<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content bgFFF">
      <el-form
        label-position="right"
        label-width="110px"
        :model="formInline"
        style="width: 400px;"
        :rules="rules"
        :ref="'formInline'"
      >
        <el-form-item label="商家" prop="stores" v-show="isEdit == false">
          <el-select v-model.trim="formInline.stores" filterable multiple size="15">
            <!-- <el-option label="请选择" value=""></el-option> -->
            <el-option
              :label="value.storeName"
              :value="value.storeId"
              v-for="value in storeList"
              :key="value.storeId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="商家" prop="companyId" v-show="isEdit == true">
          <span>{{ formInline.storeNames }}</span>
        </el-form-item>
        <el-form-item label="手机号码" prop="mobile">
          <el-input :maxlength="11" v-model.trim="formInline.mobile" placeholder="请输入手机号" style="width:221px"></el-input>
        </el-form-item>
        <el-form-item :label="$t('searchModule.Login_Name')" prop="account" v-show="isEdit == false">
          <el-input
            :maxlength="20"
            v-model.trim="accountToLowerCase"
            placeholder="登录系统的用户名"
            style="width:221px"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('searchModule.Login_Name')" prop="account" v-show="isEdit == true">
          <span>{{ formInline.account }}</span>
        </el-form-item>
        <el-form-item :label="$t('searchModule.full_name')" prop="storeManagerName">
          <el-input
            :maxlength="20"
            v-model.trim="formInline.storeManagerName"
            placeholder="请输入姓名"
            style="width:221px"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('searchModule.mailbox')" prop="email">
          <el-input :maxlength="50" v-model.trim="formInline.email" placeholder="请输入邮箱" style="width:221px"></el-input>
        </el-form-item>
        <el-form-item :label="$t('searchModule.sex')" prop="sex">
          <el-radio-group v-model.trim="formInline.sex">
            <el-radio :label="1">男</el-radio>
            <el-radio :label="0">女</el-radio>
          </el-radio-group>
        </el-form-item>
        <!-- <el-form-item :label="$t('searchModule.role')" prop="managerRoleId" v-show="isEdit == true">
          <span>{{formInline.managerRoleName}}</span>
        </el-form-item> -->
      </el-form>
      <div style="text-align: left;  margin-left: 8.5%; margin-top: 40px;">
        <el-button type="primary" @click="submitData" style="width: 88px;">提交</el-button>
        <el-button type="" @click="$router.go(-1)" style="width: 88px;">取消</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import {} from "@/common/js/public.js";
export default {
  name: "hello",
  data() {
    return {
      defaultProps: {
        children: "childs",
        label: "desc"
      },
      storeList: [],
      isEdit: false,
      total: 0,
      loading: false,
      detailFlag: false,
      parkTenantId: "",
      treeList: "",
      roleResource: [],
      checkList: [],
      formInline: {
        mobile: "",
        account: "",
        storeManagerName: "",
        email: "",
        sex: 1,
        stores: []
      },
      rules: {
        resourceIds: [
          {
            required: true,
            trigger: "blur"
          }
        ],
        //          defaultP: [{
        //            required: true,
        //            message: '请选择商家类型'
        //          }],
        mobile: [
          {
            required: true,
            message: "请输入手机号",
            trigger: "blur"
          },
          {
            validator: (rule, value, callback) => {
              if (!/^\d{11}$/.test(value)) {
                callback(new Error("手机号格式不正确"));
              } else {
                if (this.isEdit && this.oldPhone == value) {
                  callback();
                } else {
                  this.$axios.get("/acb/2.0/storeManager/checkmobile/" + value).then(res => {
                    if (!res.value) {
                      callback(new Error("该手机号已经被使用"));
                    } else if (res.value) {
                      callback();
                    } else {
                      callback(new Error(res.desc));
                    }
                  });
                }
              }
            },
            trigger: "blur"
          }
        ],
        account: [
          {
            required: true,
            message: "请输入登录名",
            trigger: "blur"
          },
          {
            validator: (rule, value, callback) => {
              if (this.isEdit) {
                callback();
              }
              if (!/^(\w){2,20}$/.test(value)) {
                callback(new Error("登录名只能为英文数字下划线"));
              } else if (/^\d{2,20}$/.test(value) || /^_{2,20}$/.test(value)) {
                callback(new Error("登录名不能为纯数字和下划线"));
              } else {
                this.$axios.get("/acb/2.0/operationManager/checkaccount/" + value).then(res => {
                  if (res.value && this.oldAccount == value) {
                    callback();
                  } else if (!res.value) {
                    callback(new Error("该用户名已经被使用"));
                  } else if (res.value) {
                    callback();
                  } else {
                    callback(new Error(res.desc));
                  }
                });
              }
            },
            trigger: "blur"
          }
        ],
        storeManagerName: [
          {
            required: true,
            message: "请输入姓名",
            trigger: "blur"
          }
        ],
        stores: [
          {
            required: true,
            message: "请选择商家",
            trigger: "blur"
          }
        ],
        email: [
          {
            validator: (rule, value, callback) => {
              if (!value) {
                return callback();
              } else if (!/^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/g.test(value)) {
                return callback(new Error("该邮箱格式不正确"));
              } else {
                callback();
              }
            },
            trigger: "blur"
          }
        ]
      }
    };
  },
  methods: {
    // 添加出来的树列表
    getResource() {
      let opt = {
        method: "get",
        url: "/boss/2.0/tenantResources/list",
        data: {
          platformId: 1
        },
        success: res => {
          if (res.state == 0) {
            this.treeList = this.sortMenu(res.value);
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine')
            });
          }
        }
      };
      this.$request(opt);
    },
    getRoleResource({ managerRoleId }) {
      // 角色对应权限
      let opt = {
        url: "/boss/2.0/tenantRoles/byId/" + managerRoleId,
        method: "get",
        success: res => {
          this.$refs.tree.setCheckedKeys(res.value.split(","));
        }
      };
      this.$request(opt);
    },
    // 修正菜单
    sortMenu(res) {
      let arr = [];
      res.forEach(value => {
        value.childs = [];
        if (value.parentCode == 0) {
          arr.push(value);
        }
      });
      function getChild(data) {
        data.forEach(value => {
          value.childs = res.filter(child => {
            return child.parentCode == value.code;
          });
          getChild(value.childs);
        });
      }
      getChild(arr);
      return arr;
    },
    // 添加
    addList() {
      this.$axios
        .post("/acb/2.0/storeManager/add", {
          data: this.formInline
        })
        .then(res => {
          if (res.state != 0) {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine')
            });
          } else {
            this.$message({
              message: res.desc,
              type: 'success'
            });
            this.$router.go(-1);
          }
        }).catch(() => {
          this.formInline.stores = this.formInline.stores.split(',');
        });
    },
    // 修改
    update() {
      this.isEdit = true;
      this.$axios
        .post("/acb/2.0/storeManager/update", {
          data: this.formInline
        })
        .then(res => {
          if (res.state == 0) {
            this.$message({
              message: res.desc,
              type: 'success'
            });
            this.$router.go(-1);
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine')
            });
          }
        });
    },
    submitData() {
      // this.formInline.resourceIds = this.$refs.tree.getCheckedKeys().join(",");
      // if (this.formInline.resourceIds == '') {
      //   this.$alert('必须选择权限', this.$t('pop_up.Tips'), {
      //     confirmButtonText: this.$t('pop_up.Determine')
      //   });
      //   return
      // }
      if (this.isEdit) {
        // 修改管理员
        this.$refs["formInline"].validate(valid => {
          if (valid) {
            this.update();
          } else {
            return false;
          }
        });
      } else {
        // 添加管理员
        this.$refs["formInline"].validate(valid => {
          if (valid) {
            this.formInline.stores = this.formInline.stores.join(',');
            this.addList();
          } else {
            return false;
          }
        });
      }
    },
    /* 商家名称的下拉框 */
    getRoleList() {
      this.$axios.get("/acb/2.0/store/listStores").then(res => {
        this.loading = false;
        if (res.state == 0) {
          this.storeList = res.value;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine')
          });
        }
      });
    },

    queryDetail() {
      this.$axios.get("/acb/2.0/storeManager/detail/" + this.$route.query.storeManagerId).then(res => {
        this.formInline = res.value;
        this.formInline.stores = res.value.storeIds;
      });
    }
  },
  components: {},
  created() {
    // this.getResource();
  },
  mounted() {
    this.getRoleList();
    if (this.$route.query.storeManagerId) {
      this.isEdit = true;
      this.formInline = this.$route.query;
      this.formInline.sex = Number(this.formInline.sex);
      this.formInline.stores = this.formInline.stores && this.formInline.stores.split(',');
      // this.getRoleResource(this.$route.query);
      // this.formInline.mobile = this.$route.query.mobile;
      // this.formInline.account = this.$route.query.account;
      // this.formInline.storeManagerName = this.$route.query.storeManagerName;
      // this.formInline.sex = this.$route.query.sex;
      // this.formInline.managerRoleId = this.$route.query.managerRoleId;
      // this.formInline.email = this.$route.query.email ? this.$route.query.email : '';
      // this.formInline.operationId = this.$route.query.operationId;
      // this.formInline.companyId = this.$route.query.companyId;
      // this.formInline.companyName = this.$route.query.companyName;
      // this.formInline.managerRoleName = this.$route.query.managerRoleName;
      this.oldPhone = this.$route.query.mobile;
      this.oldAccount = this.$route.query.account;
      this.queryDetail();
      // this.oldEmail = this.$route.query.email
    }
  },
  computed: {
    accountToLowerCase: {
      get: function() {
        return this.formInline.account;
      },
      set: function(val) {
        this.formInline.account = val.toLowerCase();
      }
    }
  }
};
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb
  height 35px
.content

  overflow hidden
  padding 20px
  border 1px solid #C0CCDA
  border-radius 4px
.el-tree
  border 0
.el-form-item__content
  margin-left 140px
</style>
